import React from 'react';
import './footer.css';
const Footer = () => {
  return (
    <footer className="bg-dark text-white py-1 footer">
      <div className="container text-center">
        <div className="row">
          <div className="col footer-col">
            <div>
              <div className="footer-title">OUR LOCATION</div>
              <p>COFFEYVILLE, KANSAS</p>
              <p>2319 W 8th - 620.251.1740</p>
            </div>
          </div>
          <div className="col footer-col">
            <div>
              <div className="footer-title">ABOUT OUR BUSINESS</div>
              <p>Thompson Brothers Welding & Industrial Supply is a leading regional distributor of welding and industrial supplies, FR apparel and safety equipment, welding and specialty gases.</p>
            </div>
          </div>
          <div className="col footer-col">
            <div>
              <div className="footer-title">Thompson Brothers Welding & Industrial Supply</div>
              <p>An American Company Family Owned Since 1948</p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
