import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

const ProductDetails = ({ addToCart, tokens }) => {
  const { itemId } = useParams();
  const [itemDetails, setItemDetails] = useState(null);
  const [selectedSize, setSelectedSize] = useState('');
  const [buttonText, setButtonText] = useState("Add to Cart");
  const [itemQuantities, setItemQuantities] = useState({});

  useEffect(() => {
    fetch(`https://api.orderfr.com/api/items/${itemId}/`)
      .then((res) => res.json())
      .then((data) => setItemDetails(data));
  }, [itemId]);

  const handleAddToCart = () => {
    if (selectedSize) {
      addToCart(itemDetails, selectedSize, itemQuantities[itemDetails.id] || 1); // Also passing selectedSize here
      setButtonText("Added to Cart!");
      setTimeout(() => {
        setButtonText("Add to Cart");
      }, 2000);
    } else {
      alert('Please select a size.');
    }
  }
  
  const [hoveredSize, setHoveredSize] = useState(null);

  const getSizes = () => {
    if (itemDetails && itemDetails.sizes) {
      return itemDetails.sizes.split(',');
    }
    return [];
  };

  if (!itemDetails) return <div>Loading...</div>;

  const handleRedirectToLogin = () => {
    window.location.href = '/login';
  }

  const sizes = getSizes();
  return (
    <div className="container mt-4">
            <div style={{ paddingTop: '8vh', paddingBottom:'5vh', textAlign:'center', width:'75%', paddingLeft:'25%', backgroundColor:'227,0,227'}}/>

      <div className="card">
        <div className="row">
          <div className="col-md-6">
            <img src={itemDetails.image_url} alt={itemDetails.name} className="card-img-top"/>
          </div>
          <div className="col-md-6">
            <div className="card-body">
              <h2 className="card-title">{itemDetails.name}</h2>
              <p className="card-text"><i>Style #: {itemDetails.style_number}</i></p>
              <p className="card-text">{itemDetails.description}</p>
             <p className="card-text" style={{visibility:'hidden'}}>Price: ${itemDetails.price}</p>

              {/* Size Selector */}
              <div className="mb-3">
                <label className="form-label">Select Size:</label>
                <div className="row">
                  {sizes.map((size, index) => (
                    <div className="col-3" key={index} style={{ padding: '1px 2px' }}>
                      <button 
                        style={{
                          width: '100%',
                          backgroundColor: selectedSize === size || hoveredSize === size ? 'black' : 'white',
                          color: selectedSize === size || hoveredSize === size ? 'white' : 'black',
                          border: '1px solid black',
                          margin: '2px',
                          padding: '10px',
                        }}
                        onMouseEnter={() => setHoveredSize(size)}
                        onMouseLeave={() => setHoveredSize(null)}
                        onClick={() => setSelectedSize(size)}
                      >
                        {size}
                      </button>
                    </div>
                  ))}
                </div>
              </div>
              <input 
                  type="number" 
                  min="1" 
                  value={itemQuantities[itemDetails.id] || 1} 
                  onChange={(e) => setItemQuantities({...itemQuantities, [itemDetails.id]: e.target.value})}
              />

                {tokens ? (
                <button className="btn btn-primary" onClick={handleAddToCart}>{buttonText}</button>
              ) : (
                <button className="btn btn-warning" onClick={handleRedirectToLogin}>Please Login</button>
              )}            
            </div>
          </div>
        </div>
      </div>
    </div>
  )
};

export default ProductDetails;
