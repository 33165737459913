import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';  // Import navigate
import TBSBrandscopy from './assets/TBSBrandscopy.png';  // Import the image 
import TBSLOGO from './assets/TBSLOGO.png';  // Import the image
import './home.css';
const Home = ({ tokens }) => {
  const navigate = useNavigate();  // Initialize navigate
  const [featuredItems, setFeaturedItems] = useState([]);
  const [showModal, setShowModal] = useState(false);
  
  useEffect(() => {
    if (tokens && tokens.access) {
      fetch('https://api.orderfr.com/api/user-details/', {
        headers: {
          'Authorization': `Bearer ${tokens.access}`,
        },
      })
      .then(res => res.json())
      .then(data => {
        console.log("Fetched user data: ", data);  // Debugging line
        if (!data.preferred_contact_method) {
          setShowModal(true);
        }
      })
      .catch(error => {
        console.error("There was an error fetching the user details", error);
      });
    }
  }, [tokens]);
  

  useEffect(() => {
    fetch('https://api.orderfr.com/api/featured_items/')  // Replace this with the correct API endpoint
      .then(res => res.json())
      .then(data => {
        console.log("Featured Items: ", data);
        setFeaturedItems(data);
      })
      .catch(error => console.error("There was a problem with the fetch operation:", error));
  }, []);
  
  const backendUrl = 'https://api.orderfr.com';

  // Inline CSS for the caption background
  const captionStyle = {
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    position: 'absolute',
    bottom: '0',
    paddingLeft: '20px',
    paddingRight: '20px',
    color: 'white',
  };

  return (
    <div style={{ height: '100%'}}>

      {/* Bootstrap Carousel */}
      <div id="carouselContainer" className="carousel-container">

      <div style={{ paddingTop: '8vh', paddingBottom:'5vh', textAlign:'center', width:'75%', paddingLeft:'25%', backgroundColor:'227,0,227'}}/>

      <div id="featuredCarousel" className="carousel slide" data-bs-ride="carousel" style={{ position: 'relative' }}>
        <div className="carousel-inner">
          {featuredItems.map((item, index) => (
            <div className={`carousel-item ${index === 0 ? 'active' : ''}`} key={item.id}>
              <a href={item.featured_link} className="d-block w-100">
                <img src={`${backendUrl}${item.featured_image_url_field}`} className="d-block w-100" alt={item.name} />
              </a>
              {/* Only display the caption on non-small devices */}
              <div className={`carousel-caption d-none d-sm-block`} style={captionStyle}>
                <h5>{item.name}</h5>
              </div>
            </div>
          ))}
        </div>
        <button className="carousel-control-prev" type="button" data-bs-target="#featuredCarousel" data-bs-slide="prev">
          <span className="carousel-control-prev-icon" aria-hidden="true"></span>
        </button>
        <button className="carousel-control-next" type="button" data-bs-target="#featuredCarousel" data-bs-slide="next">
          <span className="carousel-control-next-icon" aria-hidden="true"></span>
        </button>
      </div>
      </div>
      {showModal && (
        <div className="modal show" tabIndex="-1" style={{ display: 'block' }}>
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">How Should We Reach Out to You?</h5>
              </div>
              <div className="modal-body">
                <p>Please set your preferred method of contact to enhance your shopping experience.</p>
                <div className="d-flex justify-content-center">
                  <button 
                    type="button" 
                    className="btn btn-primary" 
                    onClick={() => navigate('/account')}
                    style={{ width: '220px', margin: '10px 0' }}>
                    Update in the Account Page
                  </button>
                </div>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-secondary" onClick={() => setShowModal(false)}>Close</button>
              </div>
            </div>
          </div>
        </div>
      )}

      <div className="brand-image-container">
        <img src={TBSBrandscopy} alt="TBS Brands" className="brand-image" />
      </div>
      <div className="main-text-container">
        <p className='h3' style={{ fontSize: '2rem' }}>  {/* Adjusted font size */}
          EXCEPTIONAL SERVICE RUNS IN THE FAMILY.
        </p>
        <p style={{ fontSize: '1rem' }}> {/* Adjusted font size */}
      Hard work, honesty and integrity are the values that have shaped our company for more than 70 years. Since 1948, we have offered traditional customer service and focused on building relationships. Our customers are more to us than just account numbers - they are our friends, neighbors and family. Our priority is listening to their needs and providing the best solution.
      </p>
      </div>
      {/* Other homepage content */}
    </div>
  );
};

export default Home;
