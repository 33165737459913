import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';


const LoginForm = ({ setTokens }) => {
  const navigate = useNavigate();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [showModal, setShowModal] = useState(false);


  const handleSubmit = async (e) => {
    e.preventDefault()
    setIsLoading(true);

    // Request token
    const response = await fetch('https://api.orderfr.com/api/token/', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ username: usernameLowercase, password }),
    });

    const data = await response.json();

    if (data.access) {
      setTokens(data);
      navigate('/home');
      const userDetails = await fetch('https://api.orderfr.com/api/user-details/', {
        headers: {
          'Authorization': `Bearer ${data.access}`,
        },
      }).then(res => res.json());
    
      if (!userDetails.preferred_contact_method) {
        setShowModal(true); // Show the modal if preferred contact method is not set
      }
       // Redirect to home page
    } else {
      alert('Invalid credentials');
    }
    setIsLoading(false);
  
  };
  const usernameLowercase = username.toLowerCase();

  return (
    
    <div className="container d-flex justify-content-center align-items-center vh-100">
      <form className="bg-light p-5 rounded" onSubmit={handleSubmit}>
        <h2 className="mb-3">Login</h2>
        {error && <div className="alert alert-danger">{error}</div>}
        <div className="input-group mb-3">
          <span className="input-group-text">Username</span>
          <input
            type="text"
            className="form-control"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            required
          />
        </div>
        <div className="input-group mb-3">
          <span className="input-group-text">Password</span>
          <input
            type="password"
            className="form-control"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </div>
        <button type="submit" className="btn btn-primary" disabled={isLoading}>
          {isLoading ? 'Logging in...' : 'Login'}
        </button>
      </form>
    </div>
  );
};

export default LoginForm;
