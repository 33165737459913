import React from 'react';
import TBSLOGO from './assets/TBSLOGO.png';  // Import the image
import './index.css';
import TBSRascoFRMensSizing from './assets/TBSRascoFRMensSizing.pdf';  // Import
import TBSDrifireFRCareInstructions from './assets/TBSDrifireFRCareInstructions.pdf';  // Import
import TBSDrifireFRMensJacketsSizing from './assets/TBSDrifireFRMensJacketsSizing.pdf';  // Import
import TBSDrifireFRMensPantsSizing from './assets/TBSDrifireFRMensPantsSizing.pdf';  // Import
import TBSAriatFRMensCareInstructions from './assets/TBSAriatFRMensCareInstructions.pdf';  // Import
import TBSAriatFRMensTopsCoatsSizing from './assets/TBSAriatFRMensTopsCoatsSizing.pdf';  // Import
import TBSAriatFRMensPantsSizing from './assets/TBSAriatFRMensPantsSizing.pdf';  // Import
import TBSWranglerFRMensCareInstructions from './assets/TBSWranglerFRMensCareInstructions.pdf';  // Import
import TBSWranglerFRMensPantsSizing from './assets/TBSWranglerFRMensPantsSizing.pdf';  // Import
import TBSWranglerFRMensShirtsSizing from './assets/TBSWranglerFRMensShirtsSizing.pdf';  // Import
import TBSCarharttFRCareInstructionsFRClothing from './assets/TBSCarharttFRCareInstructionsFRClothing.pdf';  // Import
import TBSCarharttFRMensBibsSizing from './assets/TBSCarharttFRMensBibsSizing.pdf';  // Import
import TBSCarharttFRMensJacketsSizing from './assets/TBSCarharttFRMensJacketsSizing.pdf';  // Import
import TBSCarharttFRMensPantsSizing from './assets/TBSCarharttFRMensPantsSizing.pdf';  // Import
import TBSCarharttFRMensShirtsSizing from './assets/TBSCarharttFRMensShirtsSizing.pdf';  // Import
import TBSBulwarkFRCareInstructionsFRClothing from './assets/TBSBulwarkFRCareInstructionsFRClothing.pdf';  // Import
import TBSBulwarkFRMensCoverallsSizing from './assets/TBSBulwarkFRMensCoverallsSizing.pdf';  // Import
import TBSBulwarkFRMensShirtsSizing from './assets/TBSBulwarkFRMensShirtsSizing.pdf';  // Import
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';  // Import FontAwesome
import { faShoppingCart } from '@fortawesome/free-solid-svg-icons';  // Import the shopping cart icon

const Header = ({ tokens, logout, cart }) => {  // Add cart as a prop
  const totalItems = cart.length;  // Calculate the total number of items in the cart

  const handleAccountClick = () => {
    if (!tokens) {
      window.location.href = '/login';  // Redirect to login if not authenticated
      return;
    }
    window.location.href = '/account';  // Otherwise, go to the account page
  };
    return (
      <nav className="navbar navbar-expand-lg navbar-light fixed-top">
      <div className='container sticky-top'>
        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>
        <a href="/home" className="navbar-brand">
          <img src={TBSLOGO} alt="TBS Logo" className="brand-logo"/>
        </a>
        <div className="collapse navbar-collapse" id="navbarNav">
       <ul className="navbar-nav ms-auto">
          <li className="nav-item">
            <a className="nav-link" href="/products" style={{color: '#0066ff'}}>Shop</a>
          </li>
          <li className="nav-item" >
            <a className="nav-link" href="#" onClick={handleAccountClick} style={{color: '#0066ff'}}>
              Account
            </a>
          </li>
            <li className="nav-item dropdown">
              <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown"  data-bs-auto-close="false" aria-expanded="false"  style={{color: '#0066ff'}}>
                Sizing & Care
              </a>
              <ul className="dropdown-menu" aria-labelledby="navbarDropdown">

                <li className="dropend">
                  <a className="dropdown-item dropdown-toggle" href="#" id="nestedDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                    Rasco
                  </a>
                  <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="nestedDropdown">
                    <li><a className="dropdown-item" href={TBSRascoFRMensSizing} download="TBSRascoFRMensSizing.pdf">Sizing</a></li>
                  </ul>
                </li>
                <li><hr className="dropdown-divider" /></li>
                <li className="dropend">
                  <a className="dropdown-item dropdown-toggle" href="#" id="nestedDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                    Drifire
                  </a>
                  <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="nestedDropdown">
                    <li><a className="dropdown-item" href={TBSDrifireFRCareInstructions} download="TBSDrifireFRCareInstructions.pdf">Care Instructions</a></li>
                    <li><a className="dropdown-item" href={TBSDrifireFRMensJacketsSizing} download="TBSDrifireFRMensJacketsSizing.pdf">Jacket Sizing</a></li>
                    <li><a className="dropdown-item" href={TBSDrifireFRMensPantsSizing} download="TBSDrifireFRMensPantsSizing.pdf">Pants Sizing</a></li>
                  </ul>
                </li>

                <li><hr className="dropdown-divider" /></li>
                <li className="dropend">
                  <a className="dropdown-item dropdown-toggle" href="#" id="nestedDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                   Ariat
                  </a>
                  <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="nestedDropdown">
                    <li><a className="dropdown-item" href={TBSAriatFRMensCareInstructions} download="TBSAriatFRMensCareInstructions.pdf">Care Instructions</a></li>
                    <li><a className="dropdown-item" href={TBSAriatFRMensTopsCoatsSizing} download="TBSAriatFRMensTopsCoatsSizing.pdf">Coat & Shirt Sizing</a></li>
                    <li><a className="dropdown-item" href={TBSAriatFRMensPantsSizing} download="TBSAriatFRMensPantsSizing.pdf">Pants Sizing</a></li>
                  </ul>
                </li>

                <li><hr className="dropdown-divider" /></li>
                <li className="dropend">
                  <a className="dropdown-item dropdown-toggle" href="#" id="nestedDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                    Wrangler
                  </a>
                  <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="nestedDropdown">
                    <li><a className="dropdown-item" href={TBSWranglerFRMensCareInstructions}>Care Instructions</a></li>
                    <li><a className="dropdown-item" href={TBSWranglerFRMensShirtsSizing} download="TBSWranglerFRMensShirtsSizing.pdf">Shirt Sizing</a></li>
                    <li><a className="dropdown-item" href={TBSWranglerFRMensPantsSizing} download="TBSWranglerFRMensPantsSizing.pdf">Pants Sizing</a></li>                  
                  </ul>
                </li>

                <li><hr className="dropdown-divider" /></li>
                <li className="dropend">
                  <a className="dropdown-item dropdown-toggle" href="#" id="nestedDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                    Carhartt
                  </a>
                  <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="nestedDropdown">
                    <li><a className="dropdown-item" href={TBSCarharttFRCareInstructionsFRClothing} download="TBSCarharttFRCareInstructionsFRClothing">Care Instructions</a></li>
                    <li><a className="dropdown-item" href={TBSCarharttFRMensBibsSizing} download="TBSCarharttFRMensBibsSizing.pdf">Bib Sizing</a></li>
                    <li><a className="dropdown-item" href={TBSCarharttFRMensJacketsSizing} download="TBSCarharttFRMensJacketsSizing.pdf">Jacket Sizing</a></li>
                    <li><a className="dropdown-item" href={TBSCarharttFRMensPantsSizing} download="TBSCarharttFRMensPantsSizing.pdf">Pants Sizing</a></li>
                    <li><a className="dropdown-item" href={TBSCarharttFRMensShirtsSizing} download="TBSCarharttFRMensShirtsSizing.pdf">Shirt Sizing</a></li>

                  </ul>
                </li>

                <li><hr className="dropdown-divider" /></li>
                <li className="dropend">
                  <a className="dropdown-item dropdown-toggle" href="#" id="nestedDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                    Bulwark
                  </a>
                  <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="nestedDropdown">
                    <li><a className="dropdown-item" href={TBSBulwarkFRCareInstructionsFRClothing} download="TBSBulwarkFRCareInstructionsFRClothing.pdf">Care Instructions</a></li>
                    <li><a className="dropdown-item" href={TBSBulwarkFRMensCoverallsSizing} download="TBSBulwarkFRMensCoverallsSizing.pdf">Coverall Sizing</a></li>
                    <li><a className="dropdown-item" href={TBSBulwarkFRMensShirtsSizing} download="TBSBulwarkFRMensShirtsSizing.pdf">Shirt Sizing</a></li>
                  </ul>
                </li>

              </ul>
            </li>
          {tokens ? (
            <li className="nav-item">
              <button onClick={logout} style={{color: '#0066ff', backgroundColor:'white',fontStyle: 'bold'}}>Logout</button>
            </li>
          ) : (
            <li className="nav-item" >
              <a className="nav-link" href="/login" style={{color: '#0066ff', fontStyle: 'bold'}}>Login</a>
            </li>
          )}
          <li className="nav-item nav-cart-item">
            <a className="nav-link d-flex align-items-center" href="/cart" style={{ color: '#0066ff' }}>
              <FontAwesomeIcon icon={faShoppingCart} />
              <span className="badge badge-light ml-2" style={{color: '#0066ff', fontStyle: 'bold'}}>{totalItems}</span>
            </a>
          </li>

        </ul>
        </div>
      </div>
    </nav>
  );
};

export default Header;
