import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom'; // Import Link from react-router-dom

const OrderHistory = ({ tokens }) => {
    const [orders, setOrders] = useState([]);
    const reversedOrders = [...orders].reverse();

    useEffect(() => {
        // Fetch the order history
        fetch('https://api.orderfr.com/api/order-history/', {
            headers: {
                'Authorization': `Bearer ${tokens.access}`,
            },
        })
        .then(res => res.json())
        .then(data => {
            console.log("Fetched data:", data);  // Debug log
            setOrders(data);
        })
        .catch(error => {
            console.error("There was an error fetching the order history", error);
        });
    }, []);

    const backendUrl = (process.env.REACT_APP_BACKEND_URL || "https://api.orderfr.com").replace(/\/$/, "");

    return (
        <div className="container mt-4">
          <h2>Order History</h2>
          {reversedOrders.map((order, index) => (
            <div key={index}>
              <h3>Order #{order.id}</h3>
              <p>Placed At: {new Date(order.placed_at).toLocaleString()}</p>
              <ul className="list-group">
                {order.order_items.map((orderItem, index) => (
                  <li key={index} className="list-group-item d-flex justify-content-between align-items-center">
                    <div className="d-flex align-items-center">
                      <img src={`${backendUrl}${orderItem.item.image_url}`} alt={orderItem.item.name} style={{ width: '50px', marginRight: '15px' }} />
                      {orderItem.item.name} - {orderItem.size} (Quantity: {orderItem.quantity})
                    </div>
                    {/* View Item Button */}
                    <Link to={`/product/${orderItem.item.id}`} className="btn btn-primary">View Item</Link>
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      );    
  };

export default OrderHistory;
