import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap'; // Import from Bootstrap or any other library
import { Link } from 'react-router-dom';
const CartPage = ({ cart, removeFromCart, placeOrder, tokens }) => {

  const [isLoading, setIsLoading] = useState(false);
  const [showModal, setShowModal] = useState(false); // State for modal visibility
  const totalItems = cart.length;
  const [pickupLocations, setPickupLocations] = useState([]);
  const [selectedPickupLocation, setSelectedPickupLocation] = useState('6'); // Initialized as an empty string
  const [preferredContactMethod, setPreferredContactMethod] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [email, setEmail] = useState('');
  const [showContactModal, setShowContactModal] = useState(false);

const [modalMessage, setModalMessage] = useState('');
useEffect(() => {
  fetchUserDetails();
  fetchPickupLocations();
}, [tokens]); // Tokens dependency to refetch if tokens change

  const handleModalDisplay = (message) => {
    setModalMessage(message);
    setShowModal(true);
  };

  
  const fetchUserDetails = async () => {
    if (tokens) {
      try {
        const response = await fetch('https://api.orderfr.com/api/user-details/', {
          headers: { 'Authorization': `Bearer ${tokens.access}` },
        });
        const data = await response.json();
        setPreferredContactMethod(data.preferred_contact_method || '');
        setPhoneNumber(data.phone_number || '');
        setEmail(data.email || '');
      } catch (error) {
        console.error("Failed to fetch user details:", error);
      }
    }
  };

  useEffect(() => {
    // Fetch existing user details
    fetch('https://api.orderfr.com/api/user-details/', {
      headers: {
        'Authorization': `Bearer ${tokens.access}`,
      },
    })
    .then(res => res.json())
    .then(data => {
      console.log('Fetched user details:', data);  // Debug line
      setPreferredContactMethod(data.preferred_contact_method);
      setPhoneNumber(data.phone_number || '');
      setEmail(data.email || '');
    })
    .catch(error => {
      console.error("There was an error fetching the user details", error);
    });
  }, []);

  const handlePlaceOrderClick = () => {
    if (!preferredContactMethod) {
      // Show contact method form if not set
      setShowContactModal(true);
    } else {
      // Place order logic here
    }
  };


  const handleContactFormSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
  
    const payload = {
      preferred_contact_method: preferredContactMethod,
      phone_number: phoneNumber,
      // Assuming you don't need to update the email here as it's likely done elsewhere
    };
  
    console.log('Sending payload:', payload);
  
    try {
      const response = await fetch('https://api.orderfr.com/api/update-user-details/', {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${tokens.access}`,
        },
        body: JSON.stringify(payload),
      });
      const data = await response.json();
      if (response.ok) {
        alert('User details updated successfully.');
        setShowContactModal(false); // Close the modal on success
      } else {
        throw new Error(data.message || 'Failed to update user details.');
      }
    } catch (error) {
      alert(error.message);
      console.error("There was an error updating user details", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handlePlaceOrder = async () => {
    if (!preferredContactMethod) {
      setShowContactModal(true);
      return;
    }
    setIsLoading(true);    // Now passing selectedPickupLocation as the first argument
    await placeOrder(selectedPickupLocation, (message) => {
      handleModalDisplay(message); // Pass the callback function
      if (message === 'Order placed successfully!') {
        // Assuming you have a way to clear the cart here
        // e.g., clearCart(); if there's such a function/context
      }
    });
    setIsLoading(false);
  };

  const fetchPickupLocations = async () => {
    try {
      const response = await fetch('https://api.orderfr.com/api/pickup-locations');
      const data = await response.json();
      setPickupLocations(data); // Add default option
    } catch (error) {
      console.error("Failed to fetch pickup locations:", error);
    }
  };

  const openModal = () => setShowModal(true);
  const closeModal = () => setShowModal(false);
  const selectedLocation = pickupLocations.find(loc => loc.id.toString() === selectedPickupLocation);

  return (
    <div className="container mt-4" style={{ height: '100%', paddingBottom: '1000px'}}>
            <div style={{ paddingTop: '8vh', paddingBottom:'5vh', textAlign:'center', width:'75%', paddingLeft:'25%', backgroundColor:'227,0,227'}}/>

      <h2>Your Cart</h2>
      <p>Total Items: {totalItems}</p>
      {totalItems === 0 ? (
          <>
          <p>Your cart is empty.</p>
          <Link to="/account" className="btn btn-secondary mt-3">Go to Account</Link> {/* Update this path as needed */}
        </>
      ) : (
        <>
      <div className="mt-3">
        <label htmlFor="pickupLocationSelect" className="form-label">We will automatically ship to the Coffeyville Nitrogen Plant unless a pickup location is selected:</label>
        <select
          id="pickupLocationSelect"
          className="form-select"
          value={selectedPickupLocation}
          onChange={(e) => setSelectedPickupLocation(e.target.value)}
        >
          {pickupLocations.map((location) => (
            <option key={location.id} value={location.id}>
              {location.name}
            </option>
          ))}
        </select>
      </div>
          <ul className="list-group">
            {cart.map((item, index) => (
              <li key={index} className="list-group-item d-flex justify-content-between align-items-center">
                <div className="d-flex align-items-center">
                  <img src={item.image_url} alt={item.name} style={{ width: '50px', marginRight: '15px' }} />
                  {item.name} - {item.selectedSize} - Quantity: {item.quantity}
                </div>
                <button className="btn btn-danger" onClick={() => removeFromCart(index)}>Remove</button>
              </li>
            ))}
          </ul>
          <button
        className="btn btn-primary mt-3"
        onClick={openModal}
        disabled={!selectedPickupLocation} // Disable if no location is selected
      >
        Checkout
      </button>

          {/* Modal Component */}
          <Modal show={showModal} onHide={closeModal}>
            <Modal.Header closeButton>
              <Modal.Title>Checkout</Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <p>Your items will be sent to: <strong>{
                pickupLocations.find(loc => loc.id.toString() === selectedPickupLocation) 
                  ? pickupLocations.find(loc => loc.id.toString() === selectedPickupLocation).name 
                  : 'Not selected'
              }</strong></p>
              <p>Items in your cart:</p>
              {/* List items in the modal */}
              <ul className="list-group">
                {cart.map((item, index) => (
                  <li key={index} className="list-group-item">
                    {item.name} - {item.selectedSize} - Quantity: {item.quantity}
                  </li>
                ))}
              </ul>
            </Modal.Body>
            <Modal.Footer>

              <button 
                className="btn btn-success" 
                onClick={handlePlaceOrder}
                disabled={isLoading}
              >
                {isLoading ? 'Placing Order...' : 'Place Order'}
              </button>
            </Modal.Footer>
          </Modal>
                {/* Contact Method Modal */}
      <Modal show={showContactModal} onHide={() => setShowContactModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title><h2>Wait! Please Set a Preferred Contact Method</h2></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleContactFormSubmit}>
            <div className="mb-3">
              <label htmlFor="contactMethodEmail" className="form-label">Email</label>
              <input
                type="radio"
                id="contactMethodEmail"
                name="preferredContactMethod"
                value="email"
                checked={preferredContactMethod === 'email'}
                onChange={(e) => setPreferredContactMethod(e.target.value)}
              />
            </div>
            <div className="mb-3">
              <label htmlFor="contactMethodPhone" className="form-label">Phone</label>
              <input
                type="radio"
                id="contactMethodPhone"
                name="preferredContactMethod"
                value="phone"
                checked={preferredContactMethod === 'phone'}
                onChange={(e) => setPreferredContactMethod(e.target.value)}
              />
              {preferredContactMethod === 'phone' && (
                <input
                  type="text"
                  className="form-control"
                  placeholder="Phone Number"
                  value={phoneNumber}
                  onChange={(e) => setPhoneNumber(e.target.value)}
                  required
                />
              )}
            </div>
            <button type="submit" className="btn btn-primary" disabled={isLoading}>
              {isLoading ? 'Updating...' : 'Update Contact Method'}
            </button>
          </form>
        </Modal.Body>
      </Modal>
          
        </>
      )}
    </div>
  );
};

export default CartPage;