import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './custom-card.css';
import { Offcanvas, Button } from 'react-bootstrap';

const ProductListing = ({ addToCart }) => {
  const [items, setItems] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [topResults, setTopResults] = useState([]);
  const [filteredItems, setFilteredItems] = useState([]);
  const [showDropdown, setShowDropdown] = useState(false);
  const [categories, setCategories] = useState([]);
  const [brands, setBrands] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedBrand, setSelectedBrand] = useState(null);
  const backendUrl = process.env.REACT_APP_BACKEND_URL || 'https://api.orderfr.com';
  const [isCategoriesCollapsed, setIsCategoriesCollapsed] = useState(false);
  const [isBrandsCollapsed, setIsBrandsCollapsed] = useState(false);
  const [isGenderCollapsed, setIsGenderCollapsed] = useState(false);
  const [selectedGender, setSelectedGender] = useState(null);
  const [genders, setGenders] = useState([]);
  const [isFilterMenuOpen, setIsFilterMenuOpen] = useState(false);
  const [showOffcanvas, setShowOffcanvas] = useState(false);

  const handleOffcanvasClose = () => setShowOffcanvas(false);
  const handleOffcanvasShow = () => setShowOffcanvas(true);
  const toggleFilterMenu = () => {
    setIsFilterMenuOpen(!isFilterMenuOpen);
  };

  useEffect(() => {
    fetch(`${backendUrl}api/categories/`)
      .then((res) => res.json())
      .then((data) => setCategories(data));
    
    fetch(`${backendUrl}api/brands/`)
      .then((res) => res.json())
      .then((data) => setBrands(data));
    

  }, []);

  useEffect(() => {
    fetch(`${backendUrl}api/api/genders/`)
    .then(res => res.json())
    .then(data => setGenders(data))
    .catch(error => console.error('Error fetching genders:', error));
  // ... other fetch requests
}, []);
  
  const handleResetFilters = () => {
    // Refresh the page
    window.location.reload();
};
  useEffect(() => {
    fetch(`${backendUrl}api/items/`)
      .then((res) => res.json())
      .then((data) => setItems(data));
  }, []);

  useEffect(() => {
    const filtered = items.filter(item =>
      item.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setTopResults(filtered.slice(0, 3));
    setShowDropdown(searchTerm !== '');
  }, [searchTerm, items]);

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      setShowDropdown(false);
      setFilteredItems(
        items.filter(item =>
          item.name.toLowerCase().includes(searchTerm.toLowerCase())
        )
      );
    }
  };

  const handleCategoryChange = (e, category) => {
    let updatedCategory = Array.isArray(selectedCategory) ? [...selectedCategory] : [];
    if (e.target.checked) {
      updatedCategory.push(category);
    } else {
      updatedCategory = updatedCategory.filter(c => c !== category);
    }
    setSelectedCategory(updatedCategory);
  };
  
  const handleBrandChange = (e, brand) => {
    let updatedBrands = Array.isArray(selectedBrand) ? [...selectedBrand] : [];
    if (e.target.checked) {
      updatedBrands.push(brand);
    } else {
      updatedBrands = updatedBrands.filter(b => b !== brand);
    }
    setSelectedBrand(updatedBrands);
  };
  
  const handleGenderChange = (e, genderKey) => {
    let updatedGenders = Array.isArray(selectedGender) ? [...selectedGender] : [];
    if (e.target.checked) {
      updatedGenders.push(genderKey);
    } else {
      updatedGenders = updatedGenders.filter(g => g !== genderKey);
    }
    setSelectedGender(updatedGenders);
  };
  
  
  const displayItems = filteredItems.length > 0 ? filteredItems : items;
  
  const handleFilter = () => {
    let url = `${backendUrl}api/items/`;
    const params = new URLSearchParams();
  
    if (selectedCategory && selectedCategory.length) {
      params.append('category', selectedCategory.join(','));
    }
    if (selectedBrand && selectedBrand.length) {
      params.append('brand', selectedBrand.join(','));
    }
    if (selectedGender && selectedGender.length) {
      // Ensure this is the correct parameter name expected by your backend
      params.append('gender', selectedGender.join(','));
    }
  
    fetch(`${url}?${params.toString()}`)
      .then(res => res.json())
      .then(data => setItems(data))
      .catch(error => console.error('Error applying filters:', error));
  };
  
  


  return (
    <div className="container mt-4" style={{paddingTop:"12vh"}}>
 {/* Offcanvas trigger button for mobile */}
 <Button variant="primary" onClick={handleOffcanvasShow} className="d-md-none">
                Filters
            </Button>

            {/* Offcanvas Component */}
            <Offcanvas show={showOffcanvas} onHide={handleOffcanvasClose} placement="end">
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>Filters</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    {/* Mobile filter options here */}
                    {/* Example: Categories Filter Group */}
                    <div className="filter-group">
                      <h5 className="filter-title" onClick={() => setIsCategoriesCollapsed(!isCategoriesCollapsed)}>
                       Type
                       </h5>
                        <div className={isCategoriesCollapsed ? 'collapse' : ''}>
                            {categories.map((category) => (
                                <div key={category.id} className="form-check">
                                    <input
                                        type="checkbox"
                                        className="form-check-input"
                                        id={`category-${category.id}`}
                                        onChange={(e) => handleCategoryChange(e, category.name)}
                                    />
                                    <label className="form-check-label" htmlFor={`category-${category.id}`}>
                                        {category.name}
                                    </label>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="filter-group">
                      <h5 className="filter-title" onClick={() => setIsBrandsCollapsed(!isBrandsCollapsed)}>
                        Brands
                      </h5>
                      <div className={isBrandsCollapsed ? 'collapse' : ''}>
                        {brands.map((brand) => (
                          <div key={brand.id} className="form-check">
                            <input
                              type="checkbox"
                              className="form-check-input"
                              id={`brand-${brand.id}`}
                              onChange={(e) => handleBrandChange(e, brand.name)}
                            />
                            <label className="form-check-label" htmlFor={`brand-${brand.id}`}>
                              {brand.name}
                            </label>
                          </div>
                        ))}
                      </div>
                    </div>
                    <div className="filter-group">
                    <h5 className="filter-title" onClick={() => setIsGenderCollapsed(!isGenderCollapsed)}>
                    Gender 
                    </h5>
                    <div className={isGenderCollapsed ? 'collapse' : ''}>
                      {genders.map((gender) => (
                        <div key={gender.key} className="form-check">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            id={`gender-${gender.key}`}
                            onChange={(e) => handleGenderChange(e, gender.key)}
                          />
                          <label className="form-check-label" htmlFor={`gender-${gender.key}`}>
                            {gender.name}
                          </label>
                        </div>
                      ))}
                      </div>
                    </div>
                    <Button className="btn btn-primary apply-btn" onClick={handleFilter}>
                      Apply
                    </Button>
                    <Button variant="secondary" onClick={handleResetFilters}>
                        Reset Filters
                    </Button>
                    {/* Repeat for Brands and Gender */}
                </Offcanvas.Body>

            </Offcanvas>

      <div className="row">
        {/* Sidebar filters for desktop */}
        <div className="col-md-3 filter-sidebar">
          {/* Categories Filter Group */}
          <div className="filter-group">
            <h5 className="filter-title" onClick={() => setIsCategoriesCollapsed(!isCategoriesCollapsed)}>
              Type
            </h5>
            <div className={isCategoriesCollapsed ? 'collapse' : ''}>
              {categories.map((category) => (
                <div key={category.id} className="form-check text-start">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    id={`category-${category.id}`}
                    onChange={(e) => handleCategoryChange(e, category.name)}
                  />
                  <label className="form-check-label" htmlFor={`category-${category.id}`}>
                    {category.name}
                  </label>
                </div>
              ))}
            </div>
          </div>

          {/* Brands Filter Group */}
          <div className="filter-group">
            <h5 className="filter-title" onClick={() => setIsBrandsCollapsed(!isBrandsCollapsed)}>
              Brands
            </h5>
            <div className={isBrandsCollapsed ? 'collapse' : ''}>
              {brands.map((brand) => (
                <div key={brand.id} className="form-check text-start">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    id={`brand-${brand.id}`}
                    onChange={(e) => handleBrandChange(e, brand.name)}
                  />
                  <label className="form-check-label" htmlFor={`brand-${brand.id}`}>
                    {brand.name}
                  </label>
                </div>
              ))}
            </div>
          </div>

          {/* Gender Filter Group */}
          <div className="filter-group">
          <h5 className="filter-title" onClick={() => setIsGenderCollapsed(!isGenderCollapsed)}>
          Gender</h5>
          <div className={isGenderCollapsed ? 'collapse' : ''}>
              {genders.map((gender) => (
                <div key={gender.key} className="form-check text-start">
                  <input
                    type="checkbox"
                    className="form-check-input form"
                    id={`gender-${gender.key}`}
                    onChange={(e) => handleGenderChange(e, gender.key)}
                  />
                  <label className="form-check-label" htmlFor={`gender-${gender.key}`}>
                    {gender.name}
                  </label>
                </div>
              ))}
            </div>
          </div>

          <button className="btn btn-primary apply-btn" onClick={handleFilter}>
            Apply
          </button>
        </div>
      {/* Main content */}
      <div className="col-md-9">
        <h2 className="text-center mb-4">Shop</h2>
      <div className="position-relative">
        <input
          type="text"
          className="form-control"
          placeholder="Search..."
          onChange={e => setSearchTerm(e.target.value)}
          onKeyPress={handleKeyPress}
        />
        {showDropdown && (
          <div className="position-absolute w-100 bg-white border rounded" style={{ zIndex: 999 }}>
            {topResults.map((result, index) => (
      <div className="card mb-2 h-100" key={index} style={{ height: '50px' }}>
                <Link to={`/product/${result.id}`} className="text-decoration-none text-dark">
                  <div className="row no-gutters">
                    <div className="col-4">
                      <img src={result.image_url} alt={result.name} className="card-img" />
                    </div>
                    <div className="col-8">
                      <div className="card-body">
                        <h6 className="card-title">{result.name}</h6>
                        <p className="card-text">{result.description}</p>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
            ))}
          </div>
        )}
      </div>
    {/* Conditionally show "Back To Products" Button */}
    {filteredItems.length > 0 && (
      <div className="text-center my-3">
        <button className="btn btn-primary" onClick={() => window.location.reload()}>
          Back To Products
        </button>
      </div>
    )}
  <div className="row mt-4">
    {displayItems.map((item) => (
      <div className="col-lg-4 col-md-6 mb-4" key={item.id}> {/* Adjusted column sizes */}
        <div className="card custom-card"> {/* Removed inline style height */}
          <img src={item.image_url.startsWith('/media/') ? `${backendUrl}${item.image_url}` : item.image_url} alt={item.name} className="card-img-top custom-card-img-top" />
          <div className="card-body custom-card-body">
            <h5 className="card-title">{item.name}</h5>
            <Link to={`/product/${item.id}`} className="btn btn-primary">
              View Details
                </Link>
              </div>
            </div>
          </div>
        ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductListing;