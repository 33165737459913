import React, { useState, useEffect } from 'react';
import OrderHistory from './OrderHistory';

// ContactMethodForm component
const ContactMethodForm = ({ tokens }) => {
  const [preferredContactMethod, setPreferredContactMethod] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [email, setEmail] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    // Fetch existing user details
    fetch('https://api.orderfr.com/api/user-details/', {
      headers: {
        'Authorization': `Bearer ${tokens.access}`,
      },
    })
    .then(res => res.json())
    .then(data => {
      console.log('Fetched user details:', data);  // Debug line
      setPreferredContactMethod(data.preferred_contact_method);
      setPhoneNumber(data.phone_number || '');
      setEmail(data.email || '');
    })
    .catch(error => {
      console.error("There was an error fetching the user details", error);
    });
  }, []);

  const handleFormSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);
  
    const payload = {
      preferred_contact_method: preferredContactMethod,
      phone_number: phoneNumber,
      email: email,
    };
  
    // Log the payload data
    console.log('Sending payload:', payload);
  
    // Make an API request to update user details
    fetch('https://api.orderfr.com/api/update-user-details/', {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${tokens.access}`,
      },
      body: JSON.stringify(payload),
    })
    .then(res => {
      if (res.status === 200) {
        return res.json();  // Parse the response body as JSON
      } else {
        throw new Error(`Failed to update, status code: ${res.status}`);
      }
    })
    .then(data => {
      setIsLoading(false);
  
      // Log the response data
      console.log('Received data:', data);
  
      if (data.success) {  // Confirm that your backend sends this field
        alert('User details updated successfully.');
      } else {
        alert('Failed to update user details.');
      }
    })
    .catch(error => {
      setIsLoading(false);
      console.error("There was an error updating user details", error);
    });
  };
  

  return (
    <div style={{ paddingBottom: '5vh'}}>
      <h3>Contact Methods</h3>

      <form onSubmit={handleFormSubmit}>
      
        <div className="form-group">
          <label>Preferred Method of Contact</label>
          <br />
          {
          preferredContactMethod && preferredContactMethod !== "null" ? (
            <button type="submit" className="btn btn-primary" disabled={isLoading}>
              {isLoading ? 'Updating...' : 'Update'}
            </button>
          ) : (
            <div style={{color: 'red'}}>Please select a method of contact</div>
          )
        }
          <div>
            <input
              type="radio"
              id="contactMethodEmail"
              name="preferredContactMethod"
              value="email"
              checked={preferredContactMethod === "email"}
              onChange={e => setPreferredContactMethod(e.target.value)}
              disabled={isLoading}
            />
            <label htmlFor="contactMethodEmail">Email</label>
          </div>
          <div>
            <input
              type="radio"
              id="contactMethodPhone"
              name="preferredContactMethod"
              value="phone"
              checked={preferredContactMethod === "phone"}
              onChange={e => setPreferredContactMethod(e.target.value)}
              disabled={isLoading}
            />
            <label htmlFor="contactMethodPhone">Text</label>
          </div>
        </div>

        <div className="form-group">
          <label htmlFor="phoneNumber">Phone Number</label>
          <input
            type="text"
            className="form-control"
            id="phoneNumber"
            value={phoneNumber}
            onChange={e => setPhoneNumber(e.target.value)}
            disabled={isLoading}
          />
        </div>
        <div className="form-group">
          <label htmlFor="email">Email</label>
          <input
            type="email"
            className="form-control"
            id="email"
            value={email}
            readOnly  // Email is read-only
          />
        </div>
        

      </form>
    </div>
  );
};


// AccountPage component
const AccountPage = ({ tokens, userName }) => {
  
  const [activeTab, setActiveTab] = useState('orderHistory');

  return (
    <div className="container mt-4" style={{ paddingBottom: '5vh'}}>
            <div style={{ paddingTop: '8vh', paddingBottom:'5vh', textAlign:'center', width:'75%', paddingLeft:'25%', backgroundColor:'227,0,227'}}/>

      <h2>Welcome, {userName ? userName : 'Guest'}!</h2>
      <div className="row">
        <div className="col-md-3">
          <div className="list-group">
            <button
              type="button"
              className={`list-group-item list-group-item-action ${activeTab === 'orderHistory' ? 'active' : ''}`}
              onClick={() => setActiveTab('orderHistory')}
            >
              Order History
            </button>
            <button
              type="button"
              className={`list-group-item list-group-item-action ${activeTab === 'contactMethods' ? 'active' : ''}`}
              onClick={() => setActiveTab('contactMethods')}
            >
              Contact Methods
            </button>
          </div>
        </div>
        <div className="col-md-9">
          {activeTab === 'orderHistory' && <OrderHistory tokens={tokens} />}
          {activeTab === 'contactMethods' && <ContactMethodForm tokens={tokens} />}
        </div>
      </div>
    </div>
  );
};

export default AccountPage;
