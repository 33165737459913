import './App.css';
import React, { useState, useEffect } from 'react';
import LoginForm from './LoginForm';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import ProductListing from './ProductListing';
import ProductDetails from './ProductDetails';
import AccountPage from './AccountPage';
import Home from './Home';
import Header from './Header';
import Footer from './Footer';
import CartPage from './CartPage';
import { Navigate, Outlet } from 'react-router-dom';
import { Modal, Button } from 'react-bootstrap';

function App() {
  const [items, setItems] = useState([]);
  const [cart, setCart] = useState(sessionStorage.getItem('cart') ? JSON.parse(sessionStorage.getItem('cart')) : []);
  const [tokens, setTokens] = useState(sessionStorage.getItem('tokens') ? JSON.parse(sessionStorage.getItem('tokens')) : null);
  const [userDetails, setUserDetails] = useState(null);  
  const [showModal, setShowModal] = useState(false);
  const [modalMessage, setModalMessage] = useState('');


  useEffect(() => {
    if (cart.length > 0) {
      sessionStorage.setItem('cart', JSON.stringify(cart));
    } else {
      sessionStorage.removeItem('cart');
    }
  }, [cart]);
  
  useEffect(() => {
    if (tokens) {
      sessionStorage.setItem('tokens', JSON.stringify(tokens));
    } else {
      sessionStorage.removeItem('tokens');
    }
  }, [tokens]);

  useEffect(() => {
    if (tokens) {
      authenticatedFetch('https://api.orderfr.com/api/user-details', {})
        .then((data) => {
          setUserDetails(data);
        } )
        .catch((error) => {
          console.error("There was an error fetching the user details", error);
        });
          console.log("Fetching orders and user details");

        
    }
  }, [tokens]);

  const authenticatedFetch = async (url, options = {}) => {
    const response = await fetch(url, {
      ...options,
      headers: {
        ...options.headers,
        'Authorization': `Bearer ${tokens.access}`,
      },
    });
    return response.json();
  };

  const removeFromCart = (index) => {
    const newCart = [...cart];
    newCart.splice(index, 1);
    setCart(newCart);
  };

  useEffect(() => {
    fetch('https://api.orderfr.com/api/items/')
      .then((res) => res.json())
      .then((data) => setItems(data));
  }, []);

  const addToCart = (item, selectedSize, quantity = 1) => {
    if (!selectedSize) {
      alert("Please select a size before adding to cart");
      return;
    }
    
    const newItem = { ...item, quantity, selectedSize };
    setCart([...cart, newItem]);
  };
  
  const ProtectedRoute = ({ isAuthenticated }) => {
    return isAuthenticated ? <Outlet /> : <Navigate to="/login" />;
  };

  const placeOrder = async (selectedPickupLocation, callback) => {
    try {
      const userDetails = await authenticatedFetch('https://api.orderfr.com/api/user-details', {});
      const orderData = {
        user: userDetails.id,
        items: cart.map((item) => ({
          id: item.id,
          quantity: item.quantity,
          size: item.selectedSize,  // Include the size
        })),
        pickup_location_id: parseInt(selectedPickupLocation, 10)
      };
      console.log("Sending this data to backend:", orderData);  // Debug line
  
      const response = await authenticatedFetch('https://api.orderfr.com/api/orders/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(orderData),
      });
      if (response && 'id' in response) {
        callback('Order placed successfully!');
        setCart([]); // Assuming setCart is defined in the scope where placeOrder is called
      } else {
        callback('Something went wrong');
      }
    } catch (error) {
      console.error("API request failed:", error);
      callback('API request failed');
    }
  };
  

  const logout = () => {
    setTokens(null);
    window.location.href = '/home'; // Redirect to home page after logging out
  }

  return (
    <Router>
      <div className="App">
        <Header tokens={tokens} logout={logout} cart={cart} />
        <Routes>
          <Route path="/home" element={<Home tokens={tokens} />} />
          <Route path="/login" element={<LoginForm setTokens={setTokens} />} />
  
          {/* Protected routes */}
          <Route element={<ProtectedRoute isAuthenticated={!!tokens} />}>
            <Route path="/product/:itemId" element={<ProductDetails addToCart={addToCart} tokens={tokens} />} />
            <Route path="/account" element={<AccountPage tokens={tokens} userName={userDetails ? userDetails.username : ''} />} />
            <Route path="/products" element={<ProductListing addToCart={addToCart} />} />
            <Route path="/cart" element={<CartPage cart={cart} removeFromCart={removeFromCart} placeOrder={placeOrder} tokens={tokens} />} />
          </Route>
          
          {/* Redirect to Home for any other paths */}
          <Route path="*" element={<Navigate to="/home" />} />
        </Routes>
        <Footer style={{paddingTop: '20px'}}/>
      </div>
    </Router>
  );
}

export default App;
